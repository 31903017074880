import ncodedLogoGif from './assets/ncodedxb.gif';
import './styles/App.scss';
import './i18n';
import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';
import 'animate.css';
import useStores from './stores';
import PersonalInfo from './components/Form/PersonalInfo';
import Venue from './components/Form/Venue';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';

function App() {
  const { t } = useTranslation();

  const {
    globalStore: { view, setLang },
  } = useStores();

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const changeLangHandler = (lang: string) => {
    changeLanguage(lang);
    setLang(lang);
  };

  return (
    <>
      <div className='background-image'>
        <div className='background-overlay' />
      </div>
      <div className='app' style={{ height: `${height}px` }}>
        <div className='languages-wrapper'>
          <div className='languages'>
            <span
              className='language'
              role='button'
              onClick={() => changeLangHandler('en')}
            >
              EN
            </span>
            <span className='separator' />
            <span
              className='language'
              role='button'
              onClick={() => changeLangHandler('de')}
            >
              DE
            </span>
            <span className='separator' />
            <span
              className='language'
              role='button'
              onClick={() => changeLangHandler('zh')}
            >
              ZH
            </span>
          </div>
        </div>
        <div className='content'>
          {view === '0' && (
            <>
              <header className='header'>
                <img
                  src={ncodedLogoGif}
                  alt='ncoded-logo-gif'
                  className='logo'
                />
              </header>

              <p className='subtext'>{t('Texts:register')}:</p>
              {/* <Typewriter
                className='subtext'
                text={`${t('Texts:register')}:`}
                translationKey='Texts:register'
              /> */}
            </>
          )}
          {view === '0' ? <PersonalInfo /> : <Venue />}
        </div>
      </div>
    </>
  );
}

export default observer(App);
