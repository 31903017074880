import { makeAutoObservable } from 'mobx';

class GlobalStore {
  lang: string = '';
  view = '0';

  constructor() {
    makeAutoObservable(this);
  }

  setLang = (lang: string) => {
    this.lang = lang;
  };

  setView = (view: string) => {
    this.view = view;
  };
}

export default GlobalStore;
