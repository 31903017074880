import { createContext, useContext } from 'react';

import GlobalStore from './GlobalStore';
import FormStore from './FormStore';

export const stores = {
  globalStore: new GlobalStore(),
  formStore: new FormStore(),
};

const storesContext = createContext(stores);

export const useStores = () => {
  const store = useContext(storesContext);

  if (!store) {
    throw new Error('StoreProvider has not been injected');
  }

  return store;
};

export default useStores;
