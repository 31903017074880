import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './lang-en.json';
import de from './lang-de.json';
import zh from './lang-zh.json';

const resources = { en, de, zh };

export enum Language {
  english = 'en',
  german = 'de',
  chinese = 'zh',
}

i18n.use(initReactI18next).init(
  {
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: '.',
    ns: resources ? Object.keys(resources) : [],
    defaultNS: 'Common',
    interpolation: {
      escapeValue: false,
    },
  },
  (error, t) => {
    if (error) {
      console.log('i18n. something went wrong with loading', error);
    } else {
      t('key');
    }
  }
);

export const changeLanguage = (lang: 'de' | 'en' | 'zh') =>
  i18n.changeLanguage(lang);

export default i18n;
