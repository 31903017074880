import { observer } from 'mobx-react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import InputIcon from 'react-multi-date-picker/components/input_icon';

import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  label: string;
  name: string;
  nonworkingDays?: number[];
  onChange: (date: Date) => void;
  recommendedDays?: number[];
  value: Date;
}

const CustomDatePicker = ({
  label,
  name,
  nonworkingDays,
  onChange,
  recommendedDays,
  value,
}: Props) => {
  const { t } = useTranslation();

  const changeHandler = (
    date: DateObject | null,
    options: {
      validatedValue: string | string[];
      input: HTMLElement;
      isTyping: boolean;
    }
  ) => {
    const dateObj = new Date(options.validatedValue as string);

    if (date) {
      onChange(dateObj);
    }
  };

  const scrollIntoView = () => {
    const dp = document.querySelector('.rmdp-container ');

    if (dp) {
      dp.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <>
      <label htmlFor={name} id={`${name}_label`}>
        {label}
      </label>
      <DatePicker
        arrow={false}
        calendarPosition='bottom-center'
        fixMainPosition
        highlightToday={false}
        onChange={(date, options) => changeHandler(date, options)}
        onOpen={scrollIntoView}
        mapDays={({ date }) => {
          let props: any = {};

          let isRecommended = recommendedDays?.includes(date.weekDay.index);
          let isNonworking = nonworkingDays?.includes(date.weekDay.index);

          if (isNonworking) {
            props.disabled = true;
            props.className = 'nonworking-day';
          }

          if (isRecommended) {
            props.className = 'highlight-red';
          }

          return props;
        }}
        render={<InputIcon />}
        value={value}
        weekStartDayIndex={1}
      >
        <p className='calendarText'>*{t('Texts:recommendedDays')}</p>
      </DatePicker>
    </>
  );
};

export default observer(CustomDatePicker);
