import React, { useRef } from 'react';
import CustomInput from '../CustomInput';
import { useTranslation } from 'react-i18next';
import useStores from '../../stores';
import { observer } from 'mobx-react';
import SocialLink from '../SocialLink';
import { ReactComponent as SvgMsgIcon } from '../../assets/IconMsg.svg';
import { ReactComponent as SvgCallIcon } from '../../assets/IconCall.svg';
import { ReactComponent as SvgWaIcon } from '../../assets/IconWa.svg';
import { ReactComponent as SvgMailIcon } from '../../assets/IconMail.svg';

const PersonalInfo = () => {
  const {
    formStore: { data, updateFormField },
    globalStore: { setView },
  } = useStores();

  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const socialLinksRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    formRef.current?.classList.replace(
      'animate__backInRight',
      'animate__backOutLeft'
    );

    socialLinksRef.current?.classList.replace(
      'animate__backInRight',
      'animate__backOutLeft'
    );

    textRef.current?.classList.replace(
      'animate__backInRight',
      'animate__backOutLeft'
    );

    setTimeout(() => {
      setView('1');
    }, 400);
  };

  return (
    <>
      <form
        autoComplete='off'
        className='form animate__animated animate__backInRight'
        onSubmit={submitHandler}
        ref={formRef}
      >
        <div className='formGroup'>
          <CustomInput
            name='name'
            label={t('Labels:name')}
            required
            value={data.name}
            onChange={(e) => updateFormField('name', e.target.value)}
            inputProps={{ placeholder: t('Placeholder:name') }}
          />
        </div>
        <div className='formGroup'>
          <CustomInput
            name='email'
            label={t('Labels:email')}
            required
            value={data.email}
            onChange={(e) => updateFormField('email', e.target.value)}
            inputProps={{ type: 'email', placeholder: t('Placeholder:email') }}
          />
        </div>
        <div className='formGroup'>
          <CustomInput
            name='contact'
            label={t('Labels:contact')}
            required
            value={data.contact}
            onChange={(e) => updateFormField('contact', e.target.value)}
            inputProps={{
              type: 'tel',
              pattern: '^\\+?[1-9]\\d{1,14}$',
              placeholder: t('Placeholder:contact'),
            }}
          />
        </div>

        <div className='formGroup'>
          <button className='button' type='submit'>
            {t('Buttons:signup')}
          </button>
        </div>
      </form>
      <div
        ref={socialLinksRef}
        className='socialLinksWrapper animate__animated animate__backInRight'
      >
        <SocialLink href='mailto:concierge@ncodedxb.com'>
          <SvgMailIcon />
        </SocialLink>
        <SocialLink href='tel:+971527461353'>
          <SvgCallIcon />
        </SocialLink>
        <SocialLink href='https://wa.me/+971527461353'>
          <SvgWaIcon />
        </SocialLink>
        <SocialLink href='sms:+971527461353'>
          <SvgMsgIcon />
        </SocialLink>
      </div>
      <p
        ref={textRef}
        className='footerText animate__animated animate__backInRight'
      >
        {t('Texts:concierge')}
      </p>
    </>
  );
};

export default observer(PersonalInfo);
