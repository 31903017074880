import axios from 'axios';
import { makeAutoObservable } from 'mobx';

type Venue = {
  name: string;
  recommendedDays: number[];
  nonworkingDays: number[];
};

export class FormData {
  contact = '';
  date = new Date();
  email = '';
  minimumSpend = '1000 AED per person';
  name = '';
  persons = 0;
  specialRequest = '';
  time = '';
  venue?: Venue = undefined;

  constructor() {
    makeAutoObservable(this);
  }
}

class FormStore {
  lang: string = '';
  data = new FormData();
  venues: Venue[] = [];
  recommendedDays: number[] = [];
  nonworkingDays: number[] = [];

  constructor() {
    this.setVenues();
    makeAutoObservable(this);
  }

  setVenues = async () => {
    try {
      const res = await axios.get('/venues.json');

      this.venues = res.data;
      this.data.venue = this.venues[0];
      this.recommendedDays = this.venues[0].recommendedDays;
      this.nonworkingDays = this.venues[0].nonworkingDays;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  updateFormField = <T extends keyof FormData, V extends FormData[T]>(
    field: T,
    value: V
  ) => {
    if (field === 'venue' && value) {
      const venue = this.venues.find((v) => v.name === value) as Venue;
      this.updateVenue(venue);
    } else {
      this.data[field] = value;
    }
  };

  updateVenue = (venue: Venue) => {
    this.data.date = new Date();
    this.recommendedDays = venue.recommendedDays;
    this.nonworkingDays = venue.nonworkingDays;
    this.data.venue = venue;
  };

  submitForm = (submitMsg: string, errorMsg: string) => {
    axios
      .post('/sendEmail.php', {
        to: 'concierge@ncodedxb.com',
        subject: `Reservation at ${this.data.venue?.name}`,
        from: this.data.email,
        message: `
          Name: ${this.data.name}\n
          Email: ${this.data.email}\n
          Contact: ${this.data.contact}\n
          Venue: ${this.data.venue?.name}\n
          Date: ${this.data.date.toDateString()}\n
          Time: ${this.data.time}\n
          Person: ${this.data.persons}\n
          Minimum spend: ${this.data.minimumSpend}\n,
          Special Request: ${this.data.specialRequest}\n`,
      })
      .then((_response) => {
        window.location.reload();
        alert(submitMsg);
      })
      .catch((_error) => {
        alert(errorMsg);
      });
  };
}

export default FormStore;
