import { ChangeEvent, useRef } from 'react';

import CustomInput from '../CustomInput';
import { useTranslation } from 'react-i18next';
import useStores from '../../stores';
import { FormData } from '../../stores/FormStore';
import { observer } from 'mobx-react';
import DatePicker from '../DatePicker';

const minimumSpends = ['1000 AED per person', 'Request for VIP spot'];

const Venue = () => {
  const {
    formStore: {
      data,
      updateFormField,
      submitForm,
      venues,
      recommendedDays,
      nonworkingDays,
    },
  } = useStores();

  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    updateFormField(e.target.name as keyof FormData, e.target.value);
  };

  const selectHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    updateFormField(e.target.name as keyof FormData, e.target.value);
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    formRef.current?.classList.replace(
      'animate__backInRight',
      'animate__backOutLeft'
    );

    setTimeout(() => {
      submitForm(t('Texts:submitMsg'), t('Texts:errorMsg'));
    }, 400);
  };

  return (
    <>
      <form
        autoComplete='off'
        className='form animate__animated animate__backInRight'
        onSubmit={submitHandler}
        ref={formRef}
      >
        <div className='formGroup venue'>
          <label htmlFor='venue'>{t('Labels:venue')}</label>
          <select
            id='venue'
            name='venue'
            onChange={selectHandler}
            required
            value={data.venue?.name}
          >
            {venues.map((venue) => (
              <option key={venue.name} value={venue.name}>
                {venue.name}
              </option>
            ))}
          </select>
        </div>

        <div className='formGroup'>
          <DatePicker
            recommendedDays={recommendedDays}
            nonworkingDays={nonworkingDays}
            label={t('Labels:date')}
            name='date'
            onChange={(value) => updateFormField('date', value)}
            value={data.date}
          />
        </div>

        <div className='formGroup'>
          <CustomInput
            name='time'
            label={t('Labels:time')}
            required
            value={data.time}
            inputProps={{
              type: 'time',
              placeholder: t('Placeholder:type'),
              // max: '20:00',
              // min: '08:00',
            }}
            onChange={changeHandler}
          />
        </div>

        <div className='formGroup'>
          <CustomInput
            name='persons'
            label={t('Labels:persons')}
            required
            value={data.persons.toString()}
            inputProps={{
              type: 'number',
              min: '0',
              placeholder: t('Placeholder:type'),
            }}
            onChange={changeHandler}
          />
        </div>

        <div className='formGroup'>
          <label htmlFor='minimumSpend'>{t('Labels:minimumSpend')}</label>
          <select
            id='minimumSpend'
            name='minimumSpend'
            onChange={selectHandler}
            required
            value={data.minimumSpend}
          >
            {minimumSpends.map((minSpend) => (
              <option key={minSpend} value={minSpend}>
                {minSpend}
              </option>
            ))}
          </select>
        </div>

        <div className='formGroup'>
          <CustomInput
            name='specialRequest'
            label={t('Labels:specialRequest')}
            value={data.specialRequest}
            inputProps={{
              type: 'text',
              placeholder: t('Placeholder:specialRequest'),
            }}
            onChange={changeHandler}
          />
        </div>

        <div className='formGroup'>
          <button className='button'>{t('Buttons:ncode')}</button>
        </div>
      </form>
    </>
  );
};

export default observer(Venue);
