import React, { ChangeEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormData } from '../../stores/FormStore';

interface CustomInputProps {
  className?: string;
  disabledDays?: number[];
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  label: string;
  name: keyof FormData;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  value: string;
}

const CustomInput = ({
  className,
  disabledDays,
  inputProps,
  label,
  name,
  onChange,
  required,
  value,
}: CustomInputProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (inputRef) {
      if (inputRef.current?.validity.typeMismatch) {
        inputRef.current?.setCustomValidity(t('Errors:invalidEmail'));
      } else if (inputRef.current?.validity.patternMismatch) {
        inputRef.current?.setCustomValidity(t('Errors:patterMismatch'));
      } else if (inputRef.current?.validity.valueMissing) {
        inputRef.current?.setCustomValidity(t('Errors:requiredField'));
      } else if (inputRef.current?.validity.rangeUnderflow) {
        inputRef.current?.setCustomValidity(t('Errors:rangeUnderflow'));
      } else {
        inputRef.current?.setCustomValidity('');
      }
    }
  });

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (inputProps?.type === 'date' && disabledDays) {
      const dateInput = e.target.value;
      const selectedDate = new Date(dateInput);

      const day = selectedDate.getUTCDay();

      if (disabledDays?.includes(day)) {
        alert(
          'This club is not working on this day, please choose different day.'
        );

        return;
      }
    }

    onChange(e);
  };

  // useEffect(() => {
  //   const dateInput = document.querySelector('#date') as any;
  //   dateInput?.datepicker({
  //     beforeShowDay: function (date: any) {
  //       const day = date.getDay();
  //       return [true, day === 0 || day === 6 ? 'weekend' : ''];
  //     },
  //   });
  // }, []);

  return (
    <>
      <label htmlFor={name} id={`${name}_label`}>
        {label}
      </label>
      <input
        autoComplete='off'
        className={className}
        id={name}
        name={name}
        onChange={changeHandler}
        placeholder={inputProps?.placeholder ?? 'Type...'}
        ref={inputRef}
        required={required}
        value={value}
        {...inputProps}
      />
    </>
  );
};

export default CustomInput;
