import { ReactNode } from 'react';

type Props = {
  href: string;
  children: ReactNode;
};

const SocialLink = ({ href, children }: Props) => {
  return (
    <a className='socialLink' href={href} rel='noreferrer' target='_blank'>
      {children}
    </a>
  );
};

export default SocialLink;
